/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/**
 * New Car Menu API
 * Part of CSS Module model-hub.scss
 */
((document) => {
  // Check for brand params
  const franchiseName = window.qQsMake ? window.qQsMake : '';
  const franchiseType = window.qPageUrlParam1 === 'NL' ? 'lcv' : 'car';
  const hubSection = window.qsHubSection ?? '';

  // Get the brand Ref and pageId from page params
  const brandRef = window.qBrandRef ? window.qBrandRef : '';
  const pageId = window.qPageId ? window.qPageId : '';

  // Array to store elements that need to be popualted on the page
  const hubElements = [];
  const headerHubClass = '#js-newDropDownContainer';
  const modelHubClass = '#js-modelHubContainer';

  // Text Scroller dependencies
  const textScroller = document.querySelector('.js-text-scroller');
  const lookUpContainerFragment = new DocumentFragment();

  // Store the hub data
  let hubData;
  let vanData;
  let multipleMakes;

  // Vehicle type variables
  const electricVansPage = /electric/.test(pageId);
  const vehicleTypePages =
    /small|medium|large|crew|electric|pick-up|tipper|conversions|luton|minibus|fridge/.test(pageId);
  const isVansdirect = /89/.test(brandRef);
  const vehicleTypeFragment = new DocumentFragment();
  // Default config. will be overwritten if there is global object called hubConfig
  const config = window.hubConfig
    ? window.hubConfig
    : {
        getDomain: false,
        hub: {
          justModel: false,
          hideHdr: false,
          hideNis: false,
          hideDiscoverMore: false,
          hideOtr: false,
          layout: ['xs-col-12', 's-col-4', 'm-col-3', 'l-col-2'],
          nisLink: 'Available now',
          discoverMoreLink: 'Discover more',
        },
        header: {
          justModel: false,
          hideHdr: false,
          hideNis: false,
          hideDiscoverMore: false,
          hideOtr: false,
          layout: ['xs-col-12', 's-col-4', 'm-col-3', 'l-col-2'],
          nisLink: 'Available now',
          discoverMoreLink: 'Discover more',
          vehCount: 8,
          hideAllItem: false,
        },
      };

  // -- Hide 'Discover More' link for VansDirect --
  if (brandRef && brandRef === '89') {
    window.hubConfig.hub.hideDiscoverMore = true;
  }

  // Data for view all models tile
  const allModelsIcons = {
    Audi: {
      mdlHdr: '',
      mdlDsc: 'All Audi Models',
      mdlOvrDsc: 'All Audi Models',
      mdlUrl: '/new-audi/',
      imgTxt: 'View All',
      imgSrc500: 'https://www.herefordaudi.co.uk/custom/78538.png',
      imgSrc240: 'https://www.herefordaudi.co.uk/custom/78538.png',
      mdlLowOtr: 'View models',
      newStkUrl: '',
      newStkLbl: '',
    },
    Volkswagen: {
      mdlHdr: '',
      mdlDsc: 'All Volkswagen Models',
      mdlOvrDsc: 'All Volkswagen Models',
      mdlUrl: '/new-volkswagen/',
      imgTxt: 'View All',
      imgSrc500: 'https://www.vertuvolkswagen.com/custom/78533.png',
      imgSrc240: 'https://www.vertuvolkswagen.com/custom/78533.png',
      mdlLowOtr: 'View models',
      newStkUrl: '',
      newStkLbl: '',
    },
    Toyota: {
      mdlHdr: '',
      mdlDsc: 'All Toyota Models',
      mdlOvrDsc: 'All Toyota Models',
      mdlUrl: '/new-toyota/',
      imgTxt: 'View All',
      imgSrc500: 'https://www.vertutoyota.com/custom/78537.png',
      imgSrc240: 'https://www.vertutoyota.com/custom/78537.png',
      mdlLowOtr: 'View models',
      newStkUrl: '',
      newStkLbl: '',
    },
    MercedesAMG: {
      mdlHdr: '',
      mdlDsc: 'All AMG Models',
      mdlOvrDsc: 'All AMG Models',
      mdlUrl: '/new-car-deals/mercedes-amg/',
      imgTxt: 'View All',
      imgSrc500: 'https://www.vertumercedes-benz.com/custom/78535.png',
      imgSrc240: 'https://www.vertumercedes-benz.com/custom/78535.png',
      mdlLowOtr: 'View models',
      newStkUrl: '',
      newStkLbl: '',
    },
    MercedesBenz: {
      mdlHdr: '',
      mdlDsc: 'All Mercedes-Benz Models',
      mdlOvrDsc: 'All Mercedes-Benz Models',
      mdlUrl: '/new-car-deals/mercedes-benz/',
      imgTxt: 'View All',
      imgSrc500: 'https://www.vertumercedes-benz.com/custom/78534.png',
      imgSrc240: 'https://www.vertumercedes-benz.com/custom/78534.png',
      mdlLowOtr: 'View models',
      newStkUrl: '',
      newStkLbl: '',
    },
    smart: {
      mdlHdr: '',
      mdlDsc: 'All smart Models',
      mdlOvrDsc: 'All smart Models',
      mdlUrl: '/new-car-deals/smart/',
      imgTxt: 'View All',
      imgSrc500: 'https://www.vertumercedes-benz.com/custom/78536.png',
      imgSrc240: 'https://www.vertumercedes-benz.com/custom/78536.png',
      mdlLowOtr: 'View models',
      newStkUrl: '',
      newStkLbl: '',
    },
  };

  function addSvgSymbol() {
    // SVG path variable
    const svgPath = `
            <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" xml:space="preserve" aria-hidden="true">
                <symbol id="chevron-right">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                        <path d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z" />
                    </svg>
                </symbol>
            </svg>
        `;

    // Create the div container that will contain the SVG on the page
    const modelHubSvgContainer = document.createElement('div');
    // Add the classes to the div we just created, hide by default
    modelHubSvgContainer.classList.add('model-hub-svgs', 'hide__default');
    // Add the SVG path we stored in the variable previously in the model hub svg container
    modelHubSvgContainer.innerHTML = svgPath;
    // Append the model hub svg container straight after the <body> tag
    document.body.insertBefore(modelHubSvgContainer, document.body.firstChild);
  }

  // Function to format the price with commas
  function formatNumber(mdlLowOtr) {
    const parts = mdlLowOtr.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  /**
   * Capitalize a string
   * @param {string} str The string to capitalize
   */
  // const capitalize = (str) => (!str ? '' : str.charAt(0).toUpperCase() + str.slice(1).toLowerCase());

  // Get the JSON reponse passing the franchiseName parameter that we have from the page
  function getHubData(franchiseMake, franchiseType, getDomain = config.getDomain) {
    // If we have brand params call GetMakeModelHub unless config forces calling GetDomainModelHub

    if (franchiseMake !== '' && franchiseType !== '' && !getDomain) {
      return axios.get(
        `/content/api/ajax_model_hub.p?action=GETMAKEMODELHUB&make=${franchiseMake}&vehtyp=${franchiseType}`,
      );
    }
    return axios.get('/content/api/ajax_model_hub.p?action=GETDOMAINMODELHUB');
  }

  /**
   * Build the model lookup links
   * @param {string} modelName The name of the model
   * @param {modelLink} modelLink The URL to the model
   */
  function buildModelLookupItem(modelName, modelLink) {
    const li = document.createElement('li');

    const listItem = `
          <a class="body-link font--bold" href="${modelLink}">
            ${modelName}
            <svg class="icon body-link__icon">
              <use xlink:href="#globalArrowRight"></use>
            </svg>
          </a>
        `;

    li.innerHTML = listItem;
    lookUpContainerFragment.appendChild(li);
  }

  function getBodyStyleUrl(mdlUrl, sectDsc, mdlRef, specRef) {
    // If Vansdirect and the electric vehicles
    if (isVansdirect && electricVansPage) return mdlUrl;
    let tempUrl = mdlUrl;
    let vehType;
    const bodyStyles = [
      'Convertible',
      'Coupe',
      'Hatchback',
      'Saloon',
      'Estate',
      'SUV',
      'Electric',
      'Hybrid',
    ];
    if (bodyStyles.includes(sectDsc) && mdlRef !== 0 && specRef !== 0) {
      vehType = mdlUrl.includes('van') ? 'vans' : mdlUrl.includes('car') ? 'cars' : '';
      const brokenUrl = tempUrl.split('/').filter((el) => el !== '');
      brokenUrl.splice(1, 0, `${sectDsc.toLowerCase()}-${vehType}`);
      tempUrl = `/${brokenUrl.join('/')}/`;
    }
    return tempUrl;
  }

  function getSchemaContainer(mdlOvrDsc, imgSrc240, mdlLowOtr) {
    const schemaContainer = document.createElement('script');
    schemaContainer.setAttribute('type', 'application/ld+json');

    // Build our schema structure with passing our values to it
    const schemaData = {
      '@context': 'http://schema.org',
      '@type': 'Product',
      name: mdlOvrDsc,
      image: window.location.origin + imgSrc240,
      offers: {
        '@type': 'AggregateOffer',
        lowPrice: mdlLowOtr.replace(/,/g, ''),
        priceCurrency: 'GBP',
      },
    };

    // Add the schema data to the element
    schemaContainer.innerHTML = JSON.stringify(schemaData);

    return schemaContainer;
  }

  // Build the model div passing the variable in the function
  function buildModelItem(
    {
      makeDsc,
      mdlDsc,
      mdlRef,
      specRef,
      mdlOvrDsc,
      mdlUrl,
      mdlUrlMore,
      imgSrc500,
      imgSrc240,
      mdlLowOtr,
      mdlLowCh,
      mdlLowFl,
      mdlLowHp,
      mdlLowPcp,
      imgTxt,
      mdlHdr,
      newStkUrl,
      newStkLbl,
    },
    hubConfig = {},
    sectDsc = '',
  ) {
    // SVG use path to target svg path prepended on page load
    const svgUsePath = `
        <svg class="link-icon svg-icon link-icon--secondary" aria-hidden="true" focusable="false">
          <use xlink:href="#chevron-right"></use>
        </svg>
      `;
    const svgUsePathIS = `
        <svg class="link-icon svg-icon link-icon--secondary fill--secondary" aria-hidden="true" focusable="false">
          <use xlink:href="#chevron-right"></use>
        </svg>
      `;
    // Create the model container
    const modelItemContainer = document.createElement('div');
    // Add the model class to the model container
    if (hubConfig.layout) {
      hubConfig.layout.forEach((layoutClass) => modelItemContainer.classList.add(layoutClass));
    } else {
      modelItemContainer.classList.add('xs-col-12', 's-col-4', 'm-col-3', 'l-col-2');
    }
    // Model HTML template with schema data
    mdlUrl = getBodyStyleUrl(mdlUrl, sectDsc, mdlRef, specRef);
    const vehTypeDisplay = mdlUrl.includes('van') ? 'Vans' : mdlUrl.includes('car') ? 'Cars' : '';

    const modelItem = `
        <div class="model">
          <a class="model__link" href="${mdlUrl}" title="New ${mdlOvrDsc} ${vehTypeDisplay} for Sale">
            <img class="model__img lazyload"
              src="data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 16 9%22 /%3E"
              data-srcset="${imgSrc240} 1x, ${imgSrc500} 2x" data-src="${imgSrc240}" alt="${imgTxt}">
          </a>

          <div class="model__info">
            <a class="model__link" href="${mdlUrl}" title="New ${mdlOvrDsc} ${vehTypeDisplay} for Sale">
              <h3 class="model__title">
                ${
                  makeDsc === 'smart'
                    ? `${mdlOvrDsc}`
                    : `${hubConfig.justModel ? mdlDsc : mdlOvrDsc}`
                }
              </h3>

              ${
                mdlHdr !== '' && !hubConfig.hideHdr
                  ? `<p class="hide--mobile-down">${mdlHdr}</p>`
                  : ''
              }

              ${
                mdlLowOtr !== '' &&
                mdlLowOtr !== '0' &&
                mdlLowOtr !== 'View models' &&
                !hubConfig.hideOtr
                  ? `${
                      brandRef && brandRef !== '89'
                        ? `<p class="model__price">From &pound; ${formatNumber(mdlLowOtr)}</p>`
                        : ''
                    }
                    ${
                      brandRef &&
                      brandRef === '89' &&
                      mdlLowPcp !== '' &&
                      mdlLowPcp !== '0' &&
                      !hubConfig.hideHdr
                        ? `<p class="model__price">Personal Contract hire from &pound;${formatNumber(
                            mdlLowPcp,
                          )}/mo</p>`
                        : ''
                    }
                    ${
                      brandRef &&
                      brandRef === '89' &&
                      mdlLowCh !== '' &&
                      mdlLowCh !== '0' &&
                      !hubConfig.hideHdr
                        ? `<p class="model__price">From &pound;${formatNumber(mdlLowCh)} p/m</p>`
                        : ''
                    }
                    ${
                      brandRef &&
                      brandRef === '89' &&
                      mdlLowFl !== '' &&
                      mdlLowFl !== '0' &&
                      !hubConfig.hideHdr
                        ? `<p class="model__price">Finance lease from &pound;${formatNumber(
                            mdlLowFl,
                          )}/mo</p>`
                        : ''
                    }
                    ${
                      brandRef &&
                      brandRef === '89' &&
                      mdlLowHp !== '' &&
                      mdlLowHp !== '0' &&
                      !hubConfig.hideHdr
                        ? `<p class="model__price">Hire purchase from &pound;${formatNumber(
                            mdlLowHp,
                          )}/mo</p>`
                        : ''
                    }

                    <div class="model__link">
                      <span class="heading--5">View deals</span>
                      ${svgUsePath}
                    </div>`
                  : mdlLowOtr === 'View models'
                    ? `<div class="model__link">
                        <span class="heading--5">View models</span>
                        ${svgUsePath}
                      </div>`
                    : mdlLowOtr === ''
                      ? `<div class="model__link">
                          <span class="heading--5">Learn more</span>
                          ${svgUsePath}
                        </div>`
                      : ''
              }
            </a>

          ${
            mdlUrlMore !== '' && !hubConfig.hideDiscoverMore
              ? `<a class="model__link" href="${mdlUrlMore}">
                <span class="heading--5">${
                  hubConfig.discoverMoreLink ? hubConfig.discoverMoreLink : 'Discover more'
                }</span>
                ${svgUsePath}
              </a>`
              : ''
          }
          ${
            newStkLbl !== '' && newStkUrl !== '' && !hubConfig.hideNis
              ? `<a class="model__link" href="${newStkUrl}">
                <span class="text--secondary heading--5">${
                  hubConfig.nisLink ? hubConfig.nisLink : newStkLbl
                }</span>
                ${svgUsePathIS}
              </a>`
              : ''
          }
          </a>
        </div>
      `;

    // Add the model item HTML to the model hub container
    modelItemContainer.innerHTML = modelItem;
    // Append the Schema dat to the model item
    modelItemContainer.appendChild(getSchemaContainer(mdlOvrDsc, imgSrc240, mdlLowOtr));

    // Return the model item container
    return modelItemContainer;
  }

  function getTabsContentContainer(makeDsc, ModelHubModelSection, hubConfig) {
    // Create the tabs content container
    const tabsContentContainer = document.createElement('div');

    tabsContentContainer.id = `models-${makeDsc.toLowerCase()}`;

    // Add the content class to the div alongside the ID so we can target via the tabs
    tabsContentContainer.classList.add('tabs__content');

    // Create the model hub models container
    const modelContainer = document.createElement('div');

    // Add the models class to the div
    modelContainer.classList.add('models', 'row', 'padding-top-20');

    // Create a document fragment to store the build models for quicker rendering
    const fragment = new DocumentFragment();

    // Loop through the array to build the model divs
    ModelHubModelSection.forEach((model) => {
      // Build the model tabs if the text scroller is present on the page
      if (model.sectDsc === 'All' && textScroller) {
        buildModelLookupItem(model.mdlDsc, model.mdlUrl);
      }
      // Store the data from calling the function with our required data
      const modelItem = buildModelItem(model, hubConfig, model.sectDsc);
      // Append the model template using to the fragment we declared previous
      // If the pages are vehicle type
      if (vehicleTypePages) {
        vehicleTypeFragment.appendChild(modelItem);
      } else {
        fragment.appendChild(modelItem);
      }
    });
    // Create another item for view all models
    const formattedMake = makeDsc.replace(/[\s -]+/, '');
    if (typeof allModelsIcons[formattedMake] !== 'undefined' && !config.header.hideAllItem) {
      fragment.appendChild(buildModelItem(allModelsIcons[formattedMake]));
    }

    // Append the model item fragment to the model container
    modelContainer.appendChild(fragment);

    // Append the model container inside the tabs container
    tabsContentContainer.appendChild(modelContainer);

    return tabsContentContainer;
  }

  // Check if make has popular models other wise default to all
  function getCategory(categories) {
    const defaultCategory = categories.find((category) => category.sectDsc === 'Popular Models')
      ? 'Popular Models'
      : 'All';
    // Return data for the category that will be used (i.e. popular/all)
    return categories.find((category) => category.sectDsc === `${defaultCategory}`);
  }

  function getNavTabsItem(sectCde, sectLbl, tabType = 'models', tabClass = '', tabUrl = sectCde) {
    //  Create the model hub tabs tab
    const modelHubTab = document.createElement('a');

    // Add the class to the tab
    modelHubTab.classList.add('tabs__item');

    if (tabClass !== '') {
      modelHubTab.classList.add(tabClass);
    }

    if (config.defaultTabUrl && config.defaultTabUrl.toLowerCase() === tabUrl.toLowerCase()) {
      modelHubTab.classList.add('is-active');
    }
    // Add the section/category types to the href of the anchor tags
    modelHubTab.href = `#${tabType}-${tabUrl.toLowerCase()}`;
    // Add the names of the catrgories to the tabs
    modelHubTab.textContent = sectLbl;
    return modelHubTab;
  }

  /**
   * Filter the section data
   */
  function filterSectionHubData(makeHubTabs, sectionContent, make, section, tabUrl, hubConfig) {
    makeHubTabs.appendChild(
      getNavTabsItem(make.makeUrl, make.makeDsc, 'make', 'tabs__item', tabUrl),
    );
    sectionContent.appendChild(
      getTabsContentContainer(
        section.sectCde,
        section.sectMdls.modelData.ModelHubModelSection,
        hubConfig,
      ),
    );
  }

  function handleHubData(data) {
    // Check if there is only one make to work with
    multipleMakes = data.data.hubData.ModelHubResult[0].ModelHubMake.length !== 1;
    // Assign the hub data
    hubData = data.data.hubData.ModelHubResult[0].ModelHubMake;
    if (hubData[0].makeDsc === 'Mercedes-AMG') {
      hubData.splice(2, 0, hubData[0]);
      hubData.splice(0, 1);
    }
    // Populate Model Hub and Header Hub dynamically when they are present on the page
    hubElements.forEach((hub) => {
      // Get config for the hubelement that is currently being worked on
      const hubConfig =
        hub === headerHubClass ? config.header : hub === modelHubClass ? config.hub : '';
      // Get Hub Element
      const modelHubContainer = document.querySelector(`${hub}`);
      // Create fragment to store the html until it's ready to append
      const modelHubFragmentContainer = new DocumentFragment();

      // If hub is header hub follow this path
      if (hub === headerHubClass) {
        // If there is only one make just create container with popular or all models
        if (!multipleMakes) {
          // Check if there is popular section otherwise returns all
          const category = getCategory(hubData[0].ModelHubMakeSection);
          // Check if config limits number of vehicles shown in the header then use that number to get the number of vehicles from popular/all category returen from getCategory
          const headerSection = config.header.vehCount
            ? [...category.sectMdls.ModelHubModelSection].splice(0, config.header.vehCount)
            : category.sectMdls.ModelHubModelSection;
          // Genereate tabs content
          modelHubFragmentContainer.appendChild(
            getTabsContentContainer(hubData[0].makeDsc, headerSection, hubConfig),
          );
        } else {
          // On multiple makes tabs have to be added to allow switching between them
          modelHubContainer.classList.add('tabs-container');
          // Create nav element
          const modelHubTabs = document.createElement('nav');
          // Add necessary classes
          modelHubTabs.classList.add(
            'tabs__navigation',
            'tabs--with-border-bottom',
            'tabs--scroll-mobile',
            'tabs--clean',
            'clearfix',
          );

          // For each make create nav item and content
          hubData.forEach((make) => {
            if (make.makeVehTyp !== 'lcv') {
              // Create anv item
              modelHubTabs.appendChild(getNavTabsItem(make.makeUrl, make.makeDsc));
              // Check if make has popular section otherwise uses all
              const category = getCategory(make.ModelHubMakeSection);
              // Create tabs container
              modelHubFragmentContainer.appendChild(
                getTabsContentContainer(
                  make.makeDsc,
                  category.sectMdls.ModelHubModelSection,
                  hubConfig,
                ),
              );
            }
          });
          // Append tabs to the model hub container
          modelHubContainer.appendChild(modelHubTabs);
        }
        // Append content to the modelHubContainer
        modelHubContainer.appendChild(modelHubFragmentContainer);

        return;
        // If hub is model hub follow this path
      }
      if (hub === modelHubClass) {
        // Get the title element
        const modelHubTitle = document.querySelector('.js-model-hub-title');
        // Get model hub element
        const modelHubContainer = document.querySelector(`${modelHubClass}`);
        // Give it necessary classes

        modelHubContainer.classList.add('tabs-container');
        // Create nav element
        const makeHubTabs = document.createElement('nav');
        // Give nav necessary classes
        makeHubTabs.classList.add(
          'tabs__navigation',
          'tabs--with-border-bottom',
          'tabs--scroll-mobile',
          'tabs--clean',
          'clearfix',
        );
        // Create cragment to store elements until everything is ready
        const modelHubFragmentContainer = new DocumentFragment();
        // IF it's just one make or qQsMake is present on the page do this.
        // window.qQsMake is there for mercedes to render header properly on /new-car-deals/mercedes-benz/
        if (!multipleMakes || window.qQsMake) {
          // Replace hubData with vanData to get the vans generated
          if (franchiseType === 'lcv' && hubData[0].makeVehTyp !== 'lcv') {
            hubData = vanData;
          }
          // Find the data for the make that we need to show
          let makeData = hubData[0];
          if (window.qQsMake !== '' && window.qQsMake) {
            makeData = hubData.find(
              (make) => make.makeDsc.toLowerCase() === window.qQsMake.toLowerCase(),
            );
            if (typeof makeData === 'undefined') {
              [makeData] = hubData;
            }
          }

          // Assign a value to the modelHubTitle
          if (modelHubTitle) {
            modelHubTitle.textContent = makeData.modelHubHdr;
          }

          // For each section create nav item and content
          makeData.ModelHubMakeSection.forEach((section) => {
            makeHubTabs.appendChild(getNavTabsItem(section.sectCde, section.sectLbl));
            modelHubFragmentContainer.appendChild(
              getTabsContentContainer(
                section.sectCde,
                section.sectMdls.modelData.ModelHubModelSection,
                hubConfig,
              ),
            );
          });
        } else {
          // Solving issue of pill tabs showing on brands that don't have multiple makes
          makeHubTabs.classList.add('pill-tabs');
          makeHubTabs.classList.remove('tabs--with-border-bottom');
          // If there are multiple makes for each one create
          hubData.forEach((make) => {
            let tabUrl = make.makeDsc;
            if (make.makeDsc.includes(' ')) {
              tabUrl = make.makeDsc.replace(' ', '-');
            }
            if (make.makeVehTyp === 'lcv') {
              make.makeUrl += '-vans';
            }

            if (!isVansdirect && !vehicleTypePages) {
              makeHubTabs.appendChild(
                getNavTabsItem(make.makeUrl, make.makeDsc, 'make', 'tabs__item', tabUrl),
              );
            }

            // Create fragment to store
            const makeFragmentContainer = new DocumentFragment();
            // Section to store second layer of tabs
            const makeSection = document.createElement('section');
            makeSection.id = `make-${tabUrl.toLowerCase()}`;
            // Give the section necessary classes
            makeSection.classList.add('tabs-container', 'models-container', 'tabs__content');
            // Create nav element for switching between sections
            const modelHubTabs = document.createElement('nav');
            // Add necessary classes
            modelHubTabs.classList.add(
              'tabs__navigation',
              'tabs--with-border-bottom',
              'tabs--scroll-mobile',
              'tabs--clean',
              'clearfix',
            );
            const sectionContent = new DocumentFragment();
            make.ModelHubMakeSection.forEach((section) => {
              // If it's Vansdirect
              if (isVansdirect) {
                if (hubSection !== '' && section.sectCde === hubSection) {
                  filterSectionHubData(
                    makeHubTabs,
                    sectionContent,
                    make,
                    section,
                    tabUrl,
                    hubConfig,
                  );
                }
              } else {
                modelHubTabs.appendChild(getNavTabsItem(section.sectCde, section.sectLbl));
                sectionContent.appendChild(
                  getTabsContentContainer(
                    section.sectCde,
                    section.sectMdls.ModelHubModelSection,
                    hubConfig,
                  ),
                );
              }
            });
            // Append nav and content sections to make content
            makeSection.appendChild(modelHubTabs);
            makeSection.appendChild(sectionContent);
            makeFragmentContainer.appendChild(makeSection);
            modelHubFragmentContainer.appendChild(makeFragmentContainer);
          });
        }

        if (vehicleTypePages) {
          const vehicleTypeDiv = document.createElement('div');
          vehicleTypeDiv.classList.add('models', 'row', 'padding-top-20');
          vehicleTypeDiv.appendChild(vehicleTypeFragment);
          modelHubContainer.appendChild(vehicleTypeDiv);
        } else {
          modelHubContainer.appendChild(makeHubTabs);
          modelHubContainer.appendChild(modelHubFragmentContainer);
        }
      }
    });
    // -- ToDo : Importing files into other files is often not a good idea.
    // -- Investigation is needed to see if this import can be achieved in another way.
    const isDevOrLocal =
      window.location.host.includes('dev.') || window.location.host.includes('localhost');

    if (isDevOrLocal) {
      vm.jsImport('/js/modules/dist/dev/tabs--v2.js');
    } else {
      vm.jsImport('/js/modules/dist/tabs--v2-721c7f3f-90ec6b7.js');
    }
  }

  function assignVanData(data) {
    vanData = data.data.hubData.ModelHubResult[0].ModelHubMake;
  }

  function initializeModelHub() {
    // Timeout function if the text scroller element is present
    if (textScroller) {
      setTimeout(() => {
        const textScrollerList = textScroller.querySelector('.text-scroller__list');
        textScrollerList.appendChild(lookUpContainerFragment);
        window.TextScroller.initScroller();
      }, 1000);
    }

    // On initialsie check which elements we need to populate on the page
    if (document.querySelector(headerHubClass)) {
      hubElements.push(headerHubClass);
    }

    if (document.querySelector(modelHubClass)) {
      hubElements.push(modelHubClass);
    }

    addSvgSymbol();
    // Specifically for vans we get another set of data to be able to generate new vans model hub
    if (franchiseType === 'lcv' && hubElements.indexOf('#js-newDropDownContainer') !== -1) {
      getHubData(franchiseName, franchiseType, false)
        .then(assignVanData)
        .catch((err) => {
          window.vm.debug.error('No data recieved', err);
        });
      getHubData(franchiseName, franchiseType)
        .then(handleHubData)
        .catch((err) => {
          // If we don't revcieve any data run this
          window.vm.debug.error('No data recieved', err);
        });
    } else {
      // Function we call to get the model hub data
      getHubData(franchiseName, franchiseType)
        .then(handleHubData)
        .catch((err) => {
          // If we don't revcieve any data run this
          window.vm.debug.error('No data recieved', err);
        });
    }
  }
  window.vm.onload(() => {
    initializeModelHub();
  });
})(document);
